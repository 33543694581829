import PropTypes from 'prop-types';
import cx from 'classnames';

import { Mobile, Desktop } from 'core/components/breakpoint';
import cardHOC from 'core/components/card';

import Link from 'core/components/Link';
import SmartImage from 'core/components/SmartImage';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import mapTypes from './types';
import mapSizes from '../sizes';

import styles from './index.styl';

import relationshipsPropTypes, {
  imagePropTypes,
  rubricPropTypes,
} from 'core/utils/prop-types/relationships';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';

import {
  imageVersionExists,
  doubleDashesReplacer,
  textReplacer,
} from 'site/utils';

import {
  MOBILE_MAX_WIDTH,
  REG_DOUBLE_DASHES,
  REG_TRIPLE_DASHES,
} from 'site/constants';

const requiredPayloadImports = [
  'image',
  'rubric',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'alternative_headline',
  'topic_type',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

export const imageWidthsMap = {
  huge: 908,
  xl: 908,
  l: 606,
  ml: 454,
  m: 303,
  s: 227,
  mobile: MOBILE_MAX_WIDTH,
};


function Card1(props) {
  const {
    breakpoint,
    content,
    imageRatio,
    imageVersion,
    imageWidth,
    isMobile,
    theme,
    type,
    highPriorityImage,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    alternative_headline: altHeadline,
    list_headline: listHeadline,
    topic_type: topicType,
  } = content.attributes;

  const modifyHeadline = doubleDashesReplacer(listHeadline || headline, !isMobile && '<br />');
  const modifyAltHeadline = textReplacer(altHeadline, [
    [REG_DOUBLE_DASHES, '$1&ndash;$2'],
    [REG_TRIPLE_DASHES, '&mdash;'],
  ]);

  const {
    image: {
      versions: {
        [imageVersion]: preCover,
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    rubric: {
      root_title: rubricRoot,
    },
  } = relationships(content);

  const cover = imageVersionExists(preCover) ? preCover : originalCover;

  const {
    size,
    contentAtBottom,
    showAltHeadline,
    hideIcon,
    hideRubric,
    centered,
  } = (mapTypes[type] || {})[breakpoint] || mapTypes[type] || {};

  const {
    headlineSize,
    altHeadlineSize,
    iconSize,
    contentPadding,
  } = mapSizes[size] || {};

  const icons = {
    gallery: require('site/icons/Photo').default,
    video: require('site/icons/Video').default,
  };

  const Icon = icons[topicType] || (() => null);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .icon
          order ${contentAtBottom ? '-1' : 'initial'}
          margin-top ${contentAtBottom ? '0' : '5px'}
      `}</style>
    </scope>
  );

  return (
    <div className={cx(styles.card, styles[breakpoint])}>
      <style jsx>{`
        .overlay
          transition opacity ${theme.animations.hover}

        .content
          top ${contentAtBottom ? 'auto' : 0}
          bottom ${contentAtBottom ? 0 : 'auto'}
          justify-content ${contentAtBottom ? 'flex-end' : 'flex-start'}
          align-items ${centered ? 'center' : 'flex-start'}
          text-align ${centered ? 'center' : 'left'}
          padding ${contentPadding}
          background linear-gradient(\
            to ${contentAtBottom ? 'bottom' : 'top'},\
            rgba(0, 0, 0, 0),\
            rgba(0, 0, 0, .7))

        .headline
          font-family ${theme.fonts.display}
          font-size ${headlineSize}
          color ${theme.colors.card}

        .altHeadline
          font-family ${theme.fonts.text}
          font-size ${altHeadlineSize}
          color ${theme.colors.card}

        .rubric
          font-family ${theme.fonts.display}
          color ${theme.colors.active1}
      `}</style>

      <Link
        to={link}
        type='secondary'
        className={styles.link}
      >
        <SmartImage
          src={cover}
          aspectRatio={imageRatio}
          previewSrc={previewCover}
          maxWidth={imageWidth || imageWidthsMap[isMobile ? 'mobile' : size]}
          {...highPriorityImage && { fetchPriority: 'high', instant: true }}
        />
        <div className={cx(styles.overlay, 'overlay')} />
        <div className={cx(styles.content, 'content')}>
          {!hideRubric &&
            <div
              className={cx(
                styles.rubric,
                'rubric',
              )}
            >
              {rubricRoot}
            </div>
          }
          <div
            className={cx(
              styles.headline,
              'headline',
            )}
          >
            <MarkdownWrapper children={modifyHeadline} inline />
            <Desktop>
              {
                !hideIcon && <Icon
                  width={iconSize}
                  height={iconSize}
                  className={cx(
                    styles.icon,
                    scoped.wrapClassNames('icon'),
                  )}
                />
              }
            </Desktop>
          </div>
          {showAltHeadline &&
            <div
              className={cx(
                styles.altHeadline,
                'altHeadline',
              )}
            >
              <MarkdownWrapper children={modifyAltHeadline} inline />
            </div>
          }
          <Mobile>
            {
              !hideIcon && <Icon
                width={iconSize}
                height={iconSize}
                className={cx(
                  styles.icon,
                  scoped.wrapClassNames('icon'),
                )}
              />
            }
          </Mobile>
        </div>
      </Link>
      <scoped.styles />
    </div>
  );
}

Card1.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),

  /** Переобложка изображения, которая будет использоваться в карточке вместо `original` */
  imageVersion: PropTypes.string,

  /** Позволяет контролировать соотношение сторон изображения карточки */
  imageRatio: PropTypes.number,

  /** Использование fetchPriority для загрузки картинки */
  highPriorityImage: PropTypes.bool,

  /** Ширина изображения */
  imageWidth: PropTypes.number,

  /** @ignore */
  content: relationshipsPropTypes({
    image: imagePropTypes(),
    rubric: rubricPropTypes(),
  }),

  /** @ignore */
  breakpoint: breakpointPropTypes(),

  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  theme: PropTypes.object,
};

Card1.defaultProps = {
  imageRatio: 1.5,
  imageVersion: 'list_3/2',
  type: 0,
};

const Card = cardHOC(Card1);

Card.displayName = 'Card1';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card1 as StorybookComponent };
