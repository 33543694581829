import PropTypes from 'prop-types';
import { compose } from 'core/libs/recompose';

import Loader from 'core/components/Loader';

import loader from 'core/resolver/loader';
import resolve from 'core/resolver/resolve';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import Card1 from 'site/cards/Card1';

import { EarsIndents } from 'site/components/Indents';


function GalleryLink({ topic }) {
  return (
    <EarsIndents>
      <Card1 content={topic[0]} type={5} />
    </EarsIndents>
  );
}

// TODO : вынести `gallery_id` в `core/utils/prop-types/model`, т.к. он еще будет использовавться на WMJ.
GalleryLink.propTypes = {
  block: modelPropTypes(
    PropTypes.shape({
      gallery_id: PropTypes.string.isRequired,
    })
  ),
  topic: PropTypes.arrayOf(modelPropTypes(topicAttributes).isRequired),
};

const dataProvider = resolve('topic', props => {
  const {
    bebopApi,
    block: {
      attributes: {
        gallery_id: galleryId,
      },
    },
  } = props;

  return bebopApi
    .getTopics({
      ids: galleryId,
      include: filterRequiredParams([Card1], 'include'),
      fields: filterRequiredParams([Card1], 'fields'),
    })
    .then(denormalizeData);
});

export default compose(
  loader(() => <Loader />),
  dataProvider,
)(GalleryLink);
