import PropTypes from 'prop-types';
import cx from 'classnames';

import color from 'core/libs/color';
import skip from 'core/resolver/skip';
import withTheme from 'core/components/theme';
import bindProps from 'core/components/bindProps';

import Link from 'core/components/Link';
import SmartImage from 'core/components/SmartImage';
import CommentsCount from 'core/components/CommentsCount';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Rug from 'site/components/Rug';
import Authors from 'site/components/Authors';

import mapTypes from './types';

import IconPhoto from 'site/icons/Photo.svg';
import IconVideo from 'site/icons/Video.svg';

import {
  imageVersionExists,
  doubleDashesReplacer,
  textReplacer,
  topicDateFormat,
} from 'site/utils';

import { REG_DOUBLE_DASHES, REG_TRIPLE_DASHES } from 'site/constants';

import styles from './index.styl';

const requiredPayloadImports = [
  'image',
  'rubric',
  'authors',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'alternative_headline',
  'topic_type',
  'published_at',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  rubric: [],
});

const height = 264;

const iconsMap = {
  video: IconVideo,
  gallery: IconPhoto,
};

const imageRatio = 1.5;

// TODO : В мобильной версии карточка отображается некорректно: авторы и комментарии наезжают на контент карточки
function Card4(props) {
  const {
    content,
    theme,
    type,
    isMobile,
  } = props;

  if (!content) return null;

  const {
    id,
    attributes: {
      link,
      headline,
      published_at: publishedAt,
      alternative_headline: altHeadline,
      list_headline: listHeadline,
      topic_type: topicType,
    },
  } = content;

  const modifyHeadline = doubleDashesReplacer(listHeadline || headline, !isMobile && '<br />');
  const modifyAltHeadline = textReplacer(altHeadline, [
    [REG_DOUBLE_DASHES, '$1&ndash;$2'],
    [REG_TRIPLE_DASHES, '&mdash;'],
  ]);

  const {
    image: {
      versions: {
        ['list_3/2']: preCover,
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    rubric: {
      root_title: rubricRoot,
    },
    authors,
  } = relationships(content);

  const cover = imageVersionExists(preCover) ? preCover : originalCover;

  const {
    showDateRubric,
  } = mapTypes[type] || {};

  const Icon = iconsMap[topicType] || (() => null);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.info}
          background-color ${theme.colors.card}
          transition background-color ${theme.animations.hover}

          .${styles.link}:hover &
            background-color ${color(theme.colors.active1).lighten(0.5).rgb().string()}
      `}</style>
    </scope>
  );

  return (
    <Rug>
      <style jsx>{`
        .bottomGap
        .${styles.overlayWrapper}
          flex 0 0 ${height * imageRatio}px

        .${styles.headline}
        .${styles.altHeadline}
          font-family ${theme.fonts.text}

        .${styles.headline}
          color ${theme.colors.dark}

        .${styles.altHeadline}
          color ${theme.colors.darkGrey}

        .${styles.dateRubric}
          font-family ${theme.fonts.display}
          color ${theme.colors.lightGrey}

        .${styles.overlay}
          transition opacity ${theme.animations.hover}

        :global(.names) :global(a.authorLink)
          color ${theme.colors.darkGrey}
          text-decoration-color ${theme.colors.darkGrey}
          :hover &
            color ${theme.colors.darkGrey}
      `}</style>
      <div className={styles.wrapper}>
        <Link
          to={link}
          type='secondary'
          className={cx(styles.link, scoped.wrapClassNames('link'))}
        >
          <div className={cx(styles.info, scoped.wrapClassNames('info'))}>
            <div className={styles.headline}>
              <MarkdownWrapper children={modifyHeadline} inline />
            </div>
            <div className={styles.altHeadline}>
              <MarkdownWrapper children={modifyAltHeadline} inline />
            </div>
            {showDateRubric && (
              <div className={styles.dateRubric}>
                <span className={styles.date}>
                  {topicDateFormat(publishedAt)}
                </span>
                <span className={styles.rubric}>{rubricRoot}</span>
              </div>
            )}
            <div className={styles.bottom}>
              <div className={styles.authorsAndComments}>
                {authors.length > 0 && (
                  <Authors authors={authors} small />
                )}
                <div className={styles.comments}>
                  <CommentsCount xid={id} />
                </div>
              </div>
              <div className='bottomGap' />
            </div>
          </div>
          <div className={styles.overlayWrapper}>
            <SmartImage
              src={cover}
              maxWidth={imageRatio * height}
              position='50% 0'
              aspectRatio={imageRatio}
              previewSrc={previewCover}
            />
            <div className={styles.overlay} />
            <div className={styles.overlayContent}>
              <Icon
                width={32}
                height={32}
                className={styles.icon}
              />
            </div>
          </div>
        </Link>
      </div>
      <scoped.styles />
    </Rug>
  );
}

Card4.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1]),

  /** Контент топика */
  content: modelPropTypes(topicAttributes),

  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  theme: PropTypes.object,
};

Card4.defaultProps = {
  type: 0,
};

const Card = skip(withTheme(Card4));

Card.displayName = 'Card4';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export { Card as Card4Type0 };
export const Card4Type1 = bindProps({ type: 1 })(Card);

export default Card;

export { Card4 as StorybookComponent };
