import { Route } from 'core/libs/router';

import configResolver from './shared-app-config';

import App from 'core/components/App';
import BaseRoutes from 'core/components/BaseRoutes';
import { Mobile, Desktop } from 'core/components/breakpoint';
import LegalPage from 'core/components/LegalPage';
import RulesRecommenders from 'core/components/RulesRecommenders';

import Layout from 'site/components/Layout';

import Topic from 'site/pages/topic';

import GlobalNativeStyles from './components/Ads/styles/global';

import {
  Fullscreen as DesktopFullscreen,
} from './components/Ads/desktop';

import {
  Rich,
  Fullscreen as MobileFullscreen,
} from './components/Ads/mobile';

import { baseSlug, pulseBaseUrl } from './constants';

import Main from './pages/main';
import { RubricPage, TagPage } from './pages/section';
import MediaSection from './pages/mediaSection';
import Pulse from './pages/pulse';
import Brand from './pages/brand';
import Search from './pages/search';
import Comments from './pages/comments';
import Special from './pages/special';
import Author from './pages/author';
import Authors from './pages/authors';
import Profile from './pages/profile';
import { LeaderBoard, GameCompareRubric } from './pages/gameCompare';
import About from './pages/about';

import { GAME_COMPARE_PATHNAME } from 'core/components/GameCompare/constants';

import styles from './index.styl';

export default function Site() {
  /**
   * Конфиг приложения нужно резолвить на каждую перерисовку чтоб при SSR
   * правильно резолвились переменные окружения.
   */
  const config = configResolver();

  return (
    <div className={styles.horScrollFixer}>
      <App {...config}>
        <Layout>
          <BaseRoutes
            main={Main}
            topic={Topic}
            about={About}
            search={Search}
            rubrics={RubricPage}
            tags={TagPage}
            comments={Comments}
            author={Author}
            authors={Authors}
            profile={Profile}
            legal={LegalPage}
            leaderBoard={LeaderBoard}
            rulesRecommenders={RulesRecommenders}
          >
            <Route exact
              path='/special'
              component={Special}
            />
            <Route exact
              path={pulseBaseUrl + '/:year?/:month?/:day?'}
              component={Pulse}
            />
            <Route exact
              path='/:level_1(photo|video)'
              component={MediaSection}
            />
            <Route exact
              path={`/theme/:level_2(${baseSlug})/:level_3(${baseSlug})?`}
              component={Brand}
            />
            <Route exact
              path={GAME_COMPARE_PATHNAME}
              component={GameCompareRubric}
            />
          </BaseRoutes>
        </Layout>

        <Desktop>
          <DesktopFullscreen />
          <GlobalNativeStyles />
        </Desktop>

        <Mobile>
          <Rich />
          <MobileFullscreen />
        </Mobile>
      </App>
    </div>
  );
}
